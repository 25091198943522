<template>
	<v-sheet v-if="team_id != null && teamData != null">
		<v-toolbar flat>
			<v-toolbar-title>{{teamData.name}}信息</v-toolbar-title>
			<v-spacer></v-spacer>
			<v-btn
				color="primary"
				text
				@click="$router.go(-1)"
				elevation="0">
				返回上页
			</v-btn>
		</v-toolbar>
		<v-divider></v-divider>		
		<v-container>
			<div class="d-flex align-center">
				<div class="subtitle-2 mr-2" style="width:140px">
				<v-chip
					:color="teamData.archived ? 'error' :'success'"
				>
					{{teamStatus}}
				</v-chip>
				</div>
				<div class="d-flex flex-wrap justify-end align-center flex-grow-1" v-if="user && user.role === 'admin'" >
					<v-btn small text color="info" @click="dialogEditWorker = true"><v-icon left>mdi-pencil</v-icon>编辑工作组信息</v-btn>
				</div>
			</div>
		</v-container>
		<v-divider></v-divider>
		<v-container>
			<div class="d-flex flex-column">
					<div class="subtitle-2 font-weight-bold mb-1">团队名称:</div>
					<div class="body-2">{{teamData.name}}</div>
				</div>
				<v-divider class="my-2"></v-divider>
				<div class="d-flex flex-column">
					<div class="subtitle-2 font-weight-bold mb-1">团队类型:</div>
					<div class="body-2">{{teamData.type | getTypeLabel}}</div>
				</div>
				<template v-if="teamData.owner">
					<v-divider class="my-2"></v-divider>
					<div class="d-flex flex-column">
						<div class="subtitle-2 font-weight-bold mb-1">档案创建人:</div>
						<div class="body-2">{{teamData.owner.name}}</div>
					</div>
				</template>
			</v-container>
		<v-divider></v-divider>
		<v-container class="pa-0">
			<div class="pa-1 d-flex justify-space-between align-center">
				<v-subheader>工作组成员</v-subheader>
					<div class="d-flex justify-end py-2 pr-4">
					<!--- team filter --->
					<template v-if="isFiltering">
						<div class="d-flex align-center">
						<v-btn
							color="primary"
							text
							small
							tile
							@click="dlgFilterTeamWorker = true"
							>
							<v-icon small left>
								mdi-filter-variant
							</v-icon>
							<div class="body-2 mr-2">{{filter_start_date | dateFormat}} ~ {{filter_end_date | dateFormat}}</div>
						</v-btn>
						<v-divider vertical class="mx-2"></v-divider>
							<v-btn color="error" small text @click="clearFilters">
								<v-icon small left>mdi-close</v-icon> 删除过滤
							</v-btn>
						</div>
					</template>
					<template v-else>
						<div style="max-width:150px;">
								<v-btn
									color="primary"
									text
									small
									tile
									@click="dlgFilterTeamWorker = true"
									>
									<v-icon small left>
										mdi-filter-variant
									</v-icon>
									过滤工人
								</v-btn>
						</div>
					</template>
				</div>
				<v-dialog v-model="dlgFilterTeamWorker" max-width="480">
						<v-form
							ref="form"
							v-model="valid"
							lazy-validation
							@submit.prevent="filterWorker"
						>
						<v-card>
							<v-card-title>
								过滤工人
							</v-card-title>
							<v-divider></v-divider>
								<div class="body-1 px-6 mt-4">
									过滤出在以下工作日期间工作的工人：
								</div>
								<v-container class="px-6">
									<v-row>
										<v-col>
											<v-text-field
												:value="computedDateFormattedDatefns(filter_start_date)"
												outlined
												:rules="requiredRules"
												dense
												hide-details
												label="工人开始日期"
												readonly
												required
												@click="startDateMenu = true"
												@click:clear="filter_start_date = null"
											></v-text-field>
											<v-dialog v-model="startDateMenu" max-width="290">
												<v-date-picker
													v-model="filter_start_date"
													:first-day-of-week="0"
													show-current
													locale="zh-cn"
													class="pb-6"
													@change="startDateMenu = false"
												></v-date-picker>
											</v-dialog>
										</v-col>
										<v-col>
											<v-text-field
												:value="computedDateFormattedDatefns(filter_end_date)"
												outlined
												:rules="requiredRules"
												dense
												hide-details
												label="工人结束日期"
												readonly
												required
												@click="endDateMenu = true"
												@click:clear="filter_end_date = null"
											></v-text-field>
											<v-dialog v-model="endDateMenu" max-width="290">
												<v-date-picker
													v-model="filter_end_date"
													:first-day-of-week="0"
													show-current
													locale="zh-cn"
													class="pb-6"
													@change="endDateMenu = false"
												></v-date-picker>
											</v-dialog>
										</v-col>
									</v-row>
								</v-container>
							<v-card-actions class="justify-end px-6 mt-3">
								<v-btn
									color="error"
									text
									@click="dlgFilterTeamWorker = false"
								>
									取消
								</v-btn>
								<v-btn
									:disabled="!valid"
									color="success"
									elevation="0"
									block
									@click="validate"
									type="submit"
								>
									过滤工人
								</v-btn>
							</v-card-actions>
						</v-card>
					</v-form>
				</v-dialog>
			</div>
			<v-divider></v-divider>
			<template v-if="loading">
				<v-sheet class="d-flex flex-column justify-center align-center" height="60vh">
					<v-progress-circular
						:size="70"
						:width="3"
						color="primary"
						indeterminate
					></v-progress-circular>
					<div class="subtitle-1 mt-4 ">刷新数据中</div>
				</v-sheet>
			</template>
			<template v-else>
				<div class="body-2" v-if="teamData.workers && teamData.workers.length">
					<v-data-table
						:headers="workerHeaders"
						:items="teamData.workers"
						:items-per-page="itemPerPage"
						hide-default-footer
						>
						<template v-slot:item.actions="{item}">
							<v-btn color="primary" elevation="0" small class="mr-2" 
								:to="{ name: 'viewWorker', params: {id:item.id}}"
							>
								<v-icon small left>mdi-eye</v-icon>
								详细信息				
							</v-btn>
						</template>
					</v-data-table>
				</div>
				<div class="body-2 text-center pa-3" v-else>
					工作组暂时没有任何成员
				</div>
			</template>
		</v-container>
		<!--- 编辑团队模版信息 ---->
		<v-dialog v-model="dialogEditWorker" 
			max-width="480">
				<work-team-form
					:teamId="team_id"
					@on-submit-success="onWorkTeamFormSubmit"
				/>
		</v-dialog>
	</v-sheet>
</template>

<script>

import { mapState } from 'vuex';
import WorkTeamService from '../../services/WorkTeam';
import {  parse, format } from 'date-fns';
import WorkTeamForm from '../../components/Works/WorkTeamForm.vue';

export default {
	name: 'viewTeam',
	components: {
		WorkTeamForm
	},
	created(){
		this.team_id = parseInt(this.$route.params.id);
		if(this.team_id){
			this.getTeamDetail(this.team_id);
		}
	},
	data: () => ({
		team_id: null,
		teamData: null,
		valid: true,
		requiredRules: [
			v => !!v || '必填内容',
		],
		workerHeaders: [
			{ text: '编号', value: 'id',align: 'start', },
			{ text: '工人姓名', value: 'name' },
			{ text: '出生日期', value: 'date_of_birth' },
			{ text: '开始日期', value: 'start_date' },
			{ text: '结束日期', value: 'end_date' },
	        { text: '功能', value: 'actions', sortable: false, width: 150 ,align: 'center'}
		],
		itemPerPage: 999,
		loading: false,
		filter_start_date: '',
		filter_end_date: '',
		post_start_date: '',
		post_end_date: '',
		startDateMenu: false,
		endDateMenu: false,
		dialogEditWorker: false,
		dlgFilterTeamWorker: false,
		isFiltering: false
	
	}),
	computed: {
		...mapState('user', ['user'],),
		teamStatus(){
			return this.teamData.archived ? '归档状态' :  '激活状态';
		}
	},
	filters: {
		dateFormat(value){
			let formatDate = parse(value, 'yyyy-MM-dd', new Date());
			return format(formatDate, 'dd/MM/yyyy');
		},
		getTypeLabel(value){
			return value === "team" ? "团队" : "个人";
		}
	},
	watch: {
		filter_start_date(val){
			this.post_start_date = this.formatDate(val);
		},
		filter_end_date(val){
			this.post_end_date = this.formatDate(val);
		},
	},
	methods: {
		validate () {
			this.$refs.form.validate();
		},
		computedDateFormattedDatefns (date) {
			return date ? this.formatDate(date) : ''
		},
		formatDate (date) {
			if (!date) return null

			const [year, month, day] = date.split('-')
			return `${day}/${month}/${year}`
		},
		async getTeamDetail(team_id){
			try {
				let response;
				if(this.isFiltering) {
					let param = {
						start_date: this.post_start_date ? this.post_start_date : '',
						end_date: this.post_end_date ? this.post_end_date : '',
					};
					response = await WorkTeamService.getWorkTeam(team_id, param);
				} else{
					response = await WorkTeamService.getWorkTeam(team_id);
				}
				this.teamData = response.data.data;
				setTimeout( ()=>{
					this.loading = false;
				}, 1000)
			} catch(error) {
				console.log('There has been a problem with your api call: ', error);
			}
		},
		filterWorker(){
			this.isFiltering = true;
			this.dlgFilterTeamWorker = false;
			this.loading = true;
			this.getTeamDetail(this.team_id)
		},
		clearFilters(){
			this.isFiltering = false;
			this.filter_start_date = null;
			this.filter_end_date = null;
			this.loading = true;
			this.getTeamDetail(this.team_id)
		},
		onWorkTeamFormSubmit(){
			this.dialogEditWorker = false;
			this.loading = true;
			this.getTeamDetail(this.team_id);
		}
	} 
}
</script>
